export var Cosmopolitan = {
  load(...situations) {
    situations.forEach((name) => {
      if (name.length > 0) {
        import(/* webpackMode: "lazy-once", webpackChunkName: "js/cosmopolitan" */
          `cosmopolitan/${name}`)
          .then((script) => {
            (script[name] || script[Cosmopolitan.camelCase(name)] || script.default || script).setup();
          }).then((script) => {
            $(document).trigger(`situation-loaded.cosmopolitan`, name);
          }).catch((err) => {
            console.log(`Could not load ${name}: ${err}`);
            throw err;
          });
      }
    });
  },
  
  camelCase(str) {
    return str.replace(/\//g, '_').replace(/_([a-z])/g, (m) => m[1].toUpperCase());
  },
  
  setup() {
    this.load(...Cosmopolitan.listFromAttribute(document.body));
  },
  
  listFromAttribute(el) {
    return (el.dataset.cosmopolitan || '').split(' ');
  }
};

window.addEventListener('DOMContentLoaded', function() {
  Cosmopolitan.setup();
  
  new MutationObserver(mutations => {
    for(var mutation of mutations) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'data-cosmopolitan') {
        Cosmopolitan.load(...Cosmopolitan.listFromAttribute(mutation.target));
      }
    }
  }).observe(document.body, {attributes: true});
}, false);
